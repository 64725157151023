import { Box, Typography } from "@mui/material";
import React from "react";

import ButtonExternalText from "../../components/features/ButtonExternalText";
import RHButtonText from "../../components/features/RHButtonText";
import PageCallouts from "../../components/PageCallouts";
import BootstrapCarousel from "../../components/layout/BootstrapCarousel";

const RockhouseFoundation = () => {
  return (
    <div>
      <BootstrapCarousel
        images={[
          {
            src: "https://res.cloudinary.com/dfwvu4gct/image/upload/v1665930832/RHF/unnamed_bzfbtr.jpg",
          },
        ]}
      />
      <div className="container my-4">
        <Box className="grid grid-cols-1 md:grid-cols-4 gap-7">
          <Box className="md:col-span-3">
            <Typography variant="h1" color="secondary" className="mb-2">
              ALL ABOUT THE ROCKHOUSE FOUNDATION
            </Typography>

            <Typography variant="body1">
              The <RHButtonText url="/" text="Rockhouse Foundation" /> is
              devoted to transforming the places Jamaican children learn and
              supporting the great people who teach them. All administrative and
              fundraising expenses are underwritten by the Rockhouse Hotel,
              Skylark Negril Beach Resort, Miss Lilys and their owners. This
              means every donation goes directly to support the Foundation’s
              projects and programs.
            </Typography>

            <Typography variant="h2" color="secondary">
              ABOUT THE FOUNDATION
            </Typography>

            <Typography variant="body1">
              Since 2003, the{" "}
              <RHButtonText url="/" text="Rockhouse Foundation" /> has invested
              over US$9 million in projects designed to improve childhood
              education in Western Jamaica. This includes the transformation and
              modernization of seven schools: Moreland Hill Primary and Infant
              Schools, Negril All Age School, Negril Basic School, Little Bay
              All Age and Infant Schools, Bunch of Stars Early Childhood
              Institute, Sir Clifford Campbell School and Savanna-la-Mar
              Inclusive Infant Academy (Sav Inclusive), in addition to the
              complete renovation and expansion of the Negril Community Library.
            </Typography>

            <Typography variant="h2" color="secondary">
              NOW
            </Typography>
            <Typography variant="body1">
              The <RHButtonText url="/" text="Rockhouse Foundation" /> is
              currently tackling their most ambitious project to date: creating
              a full-inclusion education community from birth through high
              school in Westmoreland’s capital. In September 2017, the
              Foundation proudly opened the doors to Jamaica’s first special
              needs early childhood school, Savanna-la-Mar Inclusive Infant
              Academy (Sav Inclusive), the first project they have designed,
              developed and built from the ground up. Today, there are 115 pre-K
              students enrolled, including children with special needs and
              regular learners who are learning side by side.
            </Typography>
            <Typography variant="body1">
              The Foundation has partnered with the Ministry of Eduction in the
              next stage of planning for the expanded Sav Inclusive Primary
              School. At the adjoining Sir Clifford Campbell School the
              Foundation renovated one building and built another in preparation
              for the expansion. We are currently undertaking additional
              planning with the Ministry for the development of the Sav
              Inclusive campus to further expand the school, a project that will
              take several years to complete in order to successfully receive
              the mixed ability population all the way through High School.
            </Typography>
            <Typography variant="h2" color="secondary">
              WHERE DO THE DONATIONS GO?
            </Typography>
            <Typography variant="body1">
              The <RHButtonText url="/" text="Rockhouse Foundation" /> focuses
              on more than just modernizing the school building itself. Not only
              is the teaching environment important, but the educators
              themselves play a key role in the quality of education the
              students are receiving.
            </Typography>
            <Typography variant="h2" color="secondary">
              WHY?
            </Typography>
            <Typography variant="body1">
              Studies have shown that in order for children to succeed,
              especially those growing up in poverty, they need a strong support
              system and foundation. The founders of the{" "}
              <RHButtonText url="/" text="Rockhouse Foundation" /> recognize
              this, and work to create beneficial learning environments for
              Western Jamaica’s youth. The hope is that through the efforts of
              the Foundation, the students can thrive not only in their
              educational pursuits but also in the larger community. Because of
              the Rockhouse Foundation, thousands of children and their families
              are positively impacted every day.
            </Typography>
            <Typography variant="h2" color="secondary">
              HOW CAN I HELP?
            </Typography>

            <Typography variant="body1">
              The <RHButtonText url="/" text="Rockhouse Foundation" /> hosts
              many events and fundraisers throughout the year to raise awareness
              and garner support for the cause. Keep an eye out for these
              happenings on social media,{" "}
              <ButtonExternalText href="mailto:@rockhousefoundation">
                @rockhousefoundation
              </ButtonExternalText>
              , and on the <RHButtonText url="/events" text="events" /> page of
              the website. You can also make a donation through the secure
              server. In the United States and Jamaica, all donations are tax
              deductible as the Foundation is a licensed, tax-exempt charity in
              both locations. Check out our list of school supplies needed, and
              pack for a purpose when next you stay at{" "}
              <ButtonExternalText href="https://www.rockhouse.com/">
                Rockhouse
              </ButtonExternalText>{" "}
              or{" "}
              <ButtonExternalText href="https://skylarknegril.com/">
                Skylark
              </ButtonExternalText>
              !
            </Typography>
          </Box>
          <Box>
            <PageCallouts />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default RockhouseFoundation;
