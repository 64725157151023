import React from "react";

const VideoPlayer = ({ url }) => {
  return (
    <iframe
      src={url}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      // className="md:w-2/3 md:max-h-full md:h-[500px] w-full h-auto mx-auto"
      className="w-full max-w-full aspect-video mx-auto max-h-[650px]"
    ></iframe>
  );
};

export default VideoPlayer;
