import React, { useState } from "react";

import {
  Button,
  Container,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomDialog from "./CustomDialog";

function Raffle() {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CustomDialog open={open} handleClose={() => setOpen(false)}>
      <Container maxWidth="xl" sx={{ mb: 2, mt: 5 }}>
        {/* <Stack direction="row" justifyContent="center" spacing={2} mb={2}>
          <Button
            size={matchesSM ? "small" : "medium"}
            variant="contained"
            target="_blank"
            sx={{
              backgroundColor: "#FED518",
              color: "#00458b",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#FED518",
                color: "#00458b",
              },
            }}
            href="/events"
          >
            Enter the Raffle
          </Button>
          <Button
            size={matchesSM ? "small" : "medium"}
            variant="contained"
            sx={{
              backgroundColor: "#f180b2",
              color: "#ffffff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#f180b2",
                color: "#ffffff",
                fontWeight: "bold",
              },
            }}
            href="https://www.gofundme.com/f/sav-inclusive-expansion-campaign-2024"
          >
            Buy Tickets
          </Button>
        </Stack> */}
        <Link
          target="_blank"
          href="https://www.gofundme.com/f/sav-inclusive-expansion-campaign-2024"
          sx={{
            cursor: "pointer",
          }}
        >
          <img
            alt="Rockhouse Foundation Summer Raffle"
            src="https://res.cloudinary.com/dfwvu4gct/image/upload/v1716043528/RHF/RHF_Raffle2024_PopUp_pac1gp.gif"
            className="xs:h-full w-full object-contain md:h-[65vh]"
          />
        </Link>
      </Container>
    </CustomDialog>
  );
}

export default Raffle;
